import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { View } from '../../shared/models/view/view.model';
import { viewNotFound } from '../../store/router/router.actions';
import { AppState } from '../../store/store.state';

export interface ViewByUrlParamResolverResult {
  dataKey: string;
  viewId: number;
}

@Injectable({
  providedIn: 'root'
})
export class ViewByUrlParamResolver {
  constructor(private readonly store: Store<AppState>, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<ViewByUrlParamResolverResult> {
    return this.store.pipe(
      select(state => state.metadata),
      filter(metadata => !metadata.loading && !!metadata.views && !!metadata.entities),
      take(1),
      switchMap(metadata => {
        let targetView: View;
        for (const id in metadata.views) {
          if (routerState.url.includes(metadata.views[id].route)) {
            targetView = metadata.views[id];
            break;
          }
        }

        if (!targetView) {
          this.store.dispatch(
            viewNotFound({
              view: routerState.url
            })
          );
          return of(undefined);
        }

        let dataKey: string;
        let entityId: number;
        return this.store.pipe(
          select(state => state),
          tap(state => {
            entityId = typeof targetView.entity === 'number' ? targetView.entity : targetView.entity.id;
            dataKey = state.metadata.entities[entityId].name;
          }),
          filter(state => !state.metadata.loading && !!state.metadata.views[targetView.id]),
          filter(state => !state.metadata.loading && !!state.metadata.entities[entityId]),
          take(1),
          map(() => {
            const result: ViewByUrlParamResolverResult = {
              dataKey,
              viewId: targetView.id
            };
            return result;
          })
        );
      })
    );
  }
}
